const Icons = {
	insta: require('../assets/SPF50 Images/insta.png'),
	spotify: require('../assets/SPF50 Images/spotify.png'),
	apple: require('../assets/SPF50 Images/appl.png'),
	yt: require('../assets/SPF50 Images/yt.png'),
	soundcloud: require('../assets/SPF50 Images/soundcloud.png'),
	footerBanner: require('../assets/SPF50 Images/banner.png'),
	SPFLogo: require('../assets/SPF50 Images/SPFTEXT.png'),
	SPFRecentAlbumCover: require('../assets/SPF50 Images/Cover.png')
};

const Merch_Photos = {
	Bonfire: require('../assets/SPF50 Images/Merch/SPFBonfire.png'),
	Merch1: require('../assets/SPF50 Images/Merch/ClassicLong.png'),
	Merch2: require('../assets/SPF50 Images/Merch/Crewneck.png'),
	Merch3: require('../assets/SPF50 Images/Merch/PremiumTee.png'),
	Merch4: require('../assets/SPF50 Images/Merch/Pullover.png'),
	Merch5: require('../assets/SPF50 Images/Merch/Tee.png')
};

import NavBar from '../modules/NavBar';
import Footer from '../modules/Footer';


export { Icons, Merch_Photos, NavBar, Footer };