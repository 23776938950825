import React, {useEffect, useState} from 'react';
import { StyleSheet, Text, View, Image, ImageBackground, Pressable, Dimensions} from 'react-native';
import { Link } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { Icons, NavBar, Footer } from '../../config/Config';

export default function SPFHome() {

	useEffect(() => {
		const script = document.createElement('script');
		script.src = '//www.instagram.com/embed.js';
		script.async = true;

		document.body.appendChild(script);
	});

	return (
		<View style={{flex: 1, backgroundColor: '#0D1317'}}>
			<NavBar active={'Home'}/>
			<View style={{flex: 1, marginTop: 15, flexDirection: 'row', justifyContent: 'space-evenly'}}>
				<View style={{backgroundColor: '#9cb6b3', flex: 1, marginLeft: 25, alignItems: 'center', borderRadius: 20, height: '95%'}}>
					<Text style={{fontSize: 24, color: '#2C2C2C'}}>
						Listen to a few of our top tracks:
					</Text>
					<iframe src="https://open.spotify.com/embed/artist/4TrQNQ9vtodaCeu4a6QjPZ?utm_source=generator" width={'95%'} height={'95%'} frameBorder={0} style={{marginBottom: 5, borderRadius: 10}}/>
				</View>
				<View style={{flex: 1.2, alignItems: 'center', height: '95%', marginHorizontal: 20}}>
					<View style={{ width: '95%', height: '100%', alignItems: 'center', backgroundColor: '#9cb6b3', borderRadius: 20}}>
						<Text style={{fontSize: 24, color: '#2C2C2C', fontWeight: '400', fontStyle: 'italic'}}>
								New Album
						</Text>
						<Text style={{fontSize: 24, color: '#2C2C2C', fontWeight: '400', marginBottom: 10, fontStyle: 'italic'}}>
								Listen on all major Streaming Platforms
						</Text>
						<View style={{height: '85%', width: '95%', marginHorizontal: 5}}>
							<Image source={Icons.SPFRecentAlbumCover} style={{width: '100%', height: '100%', alignSelf: 'baseline', resizeMode: 'contain', borderRadius: 10}}/>
						</View>
					</View>
				</View>
				<View style={{backgroundColor: '#9cb6b3', flex: 1, marginRight: 25, height: '95%', borderRadius: 20, alignItems: 'center'}}>
					<Text style={{fontSize: 24, color: '#2C2C2C'}}>
						Be sure to follow us
					</Text>
					<iframe src="https://www.instagram.com/spf50music/embed/" allowtransparency="true" width={'95%'} height={'100%'} style={{margin: 10, borderRadius: 10}} frameBorder={0}/>
				</View>
			</View>
			<Footer/>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: 'white',
		alignItems: 'center',
		justifyContent: 'center',
	},
	navText: {
		padding: 20,
		fontSize: 16,
		borderTopWidth: 1,
		borderRightWidth: 1,
		borderColor: 'black'
	}
});
