import React, {useState} from 'react';
import { StyleSheet, Text, View, Image, ImageBackground, Dimensions, SafeAreaView, Pressable} from 'react-native';
import { Link } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { Icons, NavBar, Footer } from '../../config/Config';

export default function App() {
	const [Homeactive, setHomeActive] = useState(true);

	return (
		<View style={{flex: 1, backgroundColor: '#0D1317', alignItems: 'center'}}>
			<View style={{flex: 1, width: '95%', maxHeight: '40%', alignItems: 'center', backgroundColor: '#9cb6b3', borderRadius: 20, marginTop: 5}}>
				<Text style={{fontSize: 18, color: '#2C2C2C', fontWeight: '400', fontStyle: 'italic', textAlign: 'center', marginVertical: 5}}>
					Top Tracks
				</Text>
				<iframe src="https://open.spotify.com/embed/artist/4TrQNQ9vtodaCeu4a6QjPZ" frameBorder={'0'} allowFullScreen
					style={{width:'95%', height:'100%'}}/>
				{/* <iframe src="https://open.spotify.com/embed/artist/4TrQNQ9vtodaCeu4a6QjPZ" width={'95%'} height={'352'} frameBorder={0} style={{borderRadius: 20, backgroundColor: 'green', verticalAlign: 'bottom'}}/> */}
			</View>
			<View style={{flex: 1, backgroundColor: '#9cb6b3', height: '40%', width: '95%', borderRadius: 20, marginTop: 10, alignItems: 'center'}}>
				<Text style={{fontSize: 18, color: '#2C2C2C', marginVertical: 5}}>
							Available On All Major Streaming Platforms
				</Text>
				<View style={{flex: 1, flexDirection: 'row', flexWrap: 'wrap', width: '100%', justifyContent: 'space-evenly', marginTop: 10}}>
					<Pressable style={{ width: '40%', height: '35%'}} onPress={()  => Linking.openURL('https://open.spotify.com/artist/4TrQNQ9vtodaCeu4a6QjPZ')}>
						<Image style={{ width: '100%', height: '100%'}} source={Icons.spotify} resizeMode={'contain'}/>
					</Pressable>
					<Pressable style={{ width: '40%', height: '35%'}} onPress={() => Linking.openURL('https://music.apple.com/us/artist/spf-50/1616983484')}>
						<Image style={{ width: '100%', height: '100%'}} source={Icons.apple} resizeMode={'contain'}/>
					</Pressable>
					<Pressable style={{ width: '40%', height: '35%'}} onPress={() => Linking.openURL('https://soundcloud.com/spf-50')}>
						<Image style={{ width: '100%', height: '100%'}} source={Icons.soundcloud} resizeMode={'contain'}/>
					</Pressable>
					<Pressable style={{ width: '40%', height: '35%'}} onPress={() => Linking.openURL('https://www.youtube.com/channel/UCdCWSwPFajH50IIeJOMgiJQ')}>
						<Image style={{ width: '100%', height: '100%'}} source={Icons.yt} resizeMode={'contain'}/>
					</Pressable>
				</View>
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: 'white',
		alignItems: 'center',
		justifyContent: 'center',
	},
	navText: {
		padding: 20,
		fontSize: 16,
		borderTopWidth: 1,
		borderRightWidth: 1,
		borderColor: 'black',
	}
});
