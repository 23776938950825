import React, {useState} from 'react';
import { StyleSheet, Text, View, Image, ImageBackground, Linking, Pressable} from 'react-native';
import { Link } from '@react-navigation/native';

import { Icons, NavBar } from '../config/Config';

export default function Footer() {
	return(
		<View style={{ height: 80, width: '100%', flexDirection: 'row', alignItems: 'flex-end', backgroundColor: '#9cb6b3'}}>
			<View style={{paddingBottom: 20, width: '33%', marginLeft: 15}}>
				<Text style={{color: 'white'}}>
				All Rights Reserved ©
				</Text>
				<Text style={{color: 'white'}}>
				SPF-50 Studios
				</Text>
			</View>
			<Pressable style={{height: '100%', width: '33%', flexGrow: 1}} onPress={() => {navigation.navigate('Login'), <NavBar active='NA'/>;}}>
				<Image source={Icons.footerBanner} style={{width: '100%', height: '100%', resizeMode: 'contain'}}/>
			</Pressable>
			<View style={{flexDirection: 'row-reverse', width: '33%'}}>
				<View style={{paddingBottom: 20, marginRight: 15}}>
					<Text style={{color: 'white'}}>
				Privacy Policy
					</Text>
				</View>
				<View style={{paddingBottom: 10, flexDirection: 'row', marginHorizontal: 10}}> 
					<Image source={Icons.insta} style={{width: 30, height: 30}}/>
					<Image source={Icons.spotify} style={{width: 30, height: 30, marginLeft: 10}}/>
					<Image source={Icons.apple} style={{width: 30, height: 30,  marginLeft: 10}}/>
					<Image source={Icons.yt} style={{width: 30, height: 30,  marginLeft: 10}}/>
					<Image source={Icons.soundcloud} style={{width: 30, height: 30,  marginLeft: 10}}/>
				</View>
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: 'white',
		alignItems: 'center',
		justifyContent: 'center',
	},
	navText: {
		padding: 20,
		fontSize: 16,
		borderTopWidth: 1,
		borderRightWidth: 1,
		borderColor: 'black',
	}
});