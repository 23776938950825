import React, {useState} from 'react';
import { StyleSheet, Text, View, Image, ImageBackground, Linking} from 'react-native';
import { Link } from '@react-navigation/native';

import { Icons, Footer, NavBar } from '../../config/Config';

export default function App() {
	const [Homeactive, setHomeActive] = useState(true);

	return (
		<View style={{flex: 1, backgroundColor: '#0D1317'}}>
			<NavBar active={'Contact Us'}/>
			<View style={{flexGrow: 1, marginTop: 15, flexDirection: 'row', justifyContent: 'space-evenly'}}>
				<Text>
					Test
				</Text>
			</View>
			<Footer/>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: 'white',
		alignItems: 'center',
		justifyContent: 'center',
	},
	navText: {
		padding: 20,
		fontSize: 16,
		borderTopWidth: 1,
		borderRightWidth: 1,
		borderColor: 'black',
	}
});
