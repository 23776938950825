import React, {useEffect, useState} from 'react';
import { StyleSheet, Text, View, Image, ImageBackground, Linking, Dimensions} from 'react-native';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';
import { collection, getDocs } from 'firebase/firestore';

import { Footer, Icons, NavBar } from '../../config/Config';
import { db } from '../../config/firebase';
import { FlatList } from 'react-native-gesture-handler';

function RenderPosts({caption, url}) {
	return (
		<View style={{alignItems: 'center', marginVertical: 10, width: '35%', height: Dimensions.get('window').width * .25, backgroundColor: '#9cb6b3', alignSelf: 'center', borderRadius: 20}}>
			<View style={{width: '90%', height: '90%', margin: 5}}>
				<Image source={{uri: url}} style={{width: '100%', height: '100%', aspectRatio: 1, resizeMode: 'contain', borderRadius: 30}}/>
			</View>
			<Text>
				{caption}
			</Text>
		</View>
	);
}

// correct: console.log(new Date('May 12, 2023, 7:23:40 AM UTC-4').getTime());
// console.log(new Date.getTime('May 12, 2023  7:23:40 AM UTC-4')); reference
// take the reference and replace the 'at' with '' from there get the time using correct then sort based off that.

export default function App() {
	const [Posts, setPosts] = useState([]);

	useEffect(() => {
		const fetchPosts = async() => {
			const snapshot = await getDocs(collection(db, 'Events'));
		
			const storage = getStorage();

			snapshot.forEach((doc) => {
				getDownloadURL(ref(storage, `${doc.data().ImageLink}`)).then((firebaseurl) => {
					setPosts(prevPosts => [...prevPosts, {Caption: doc.data().Caption, url: firebaseurl, timestamps: doc.data().TS.seconds}]);
				});
			});
		};
		fetchPosts();
	}, []);

	Posts.sort((a, b) => {
		return(b.timestamps - a.timestamps);
	});

	return (
		<><FlatList
			ListHeaderComponent={<NavBar active={'Events'} />}
			data={Posts}
			renderItem={({ item }) => (<RenderPosts caption={item.Caption} url={item.url} />)}
			style={{ backgroundColor: '#0D1317' }}
			showsVerticalScrollIndicator={false} /><Footer /></>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: 'white',
		alignItems: 'center',
		justifyContent: 'center',
	},
	navText: {
		padding: 20,
		fontSize: 16,
		borderTopWidth: 1,
		borderRightWidth: 1,
		borderColor: 'black',
	}
});
