import React, {useState} from 'react';
import { StyleSheet, Text, View, Image, ImageBackground, Linking, Pressable} from 'react-native';
import { Link } from '@react-navigation/native';

import { Icons, Merch_Photos, NavBar, Footer } from '../../config/Config';

export default function App() {
	const [MerchPOS, setMerchPOS] = useState(0);

	const Merch = [
		// eslint-disable-next-line react/jsx-key
		<Image source={Merch_Photos.Merch1} style={{height: '100%', width: '100%', alignSelf: 'center'}} resizeMode={'contain'}/>,
		// eslint-disable-next-line react/jsx-key
		<Image source={Merch_Photos.Merch2} style={{height: '100%', width: '100%', alignSelf: 'center'}} resizeMode={'contain'}/>,
		// eslint-disable-next-line react/jsx-key
		<Image source={Merch_Photos.Merch3} style={{height: '100%', width: '100%', alignSelf: 'center'}} resizeMode={'contain'}/>,
		// eslint-disable-next-line react/jsx-key
		<Image source={Merch_Photos.Merch4} style={{height: '100%', width: '100%', alignSelf: 'center'}} resizeMode={'contain'}/>,
		// eslint-disable-next-line react/jsx-key
		<Image source={Merch_Photos.Merch5} style={{height: '100%', width: '100%', alignSelf: 'center'}} resizeMode={'contain'}/>
	];

	return (
		<View style={{flex: 1, backgroundColor: '#0D1317'}}>
			<NavBar active={'Merch'}/>
			<View style={{flexGrow: 1}}>
				<View style={{flex: 1, marginTop: 15, flexDirection: 'row', justifyContent: 'space-evenly'}}>
					<View style={{flex: 1, alignItems: 'center', height: '95%', marginHorizontal: 20}}>
						<View style={{ width: '95%', height: '100%', alignItems: 'center', backgroundColor: '#9cb6b3', borderRadius: 20, flexDirection: 'row', justifyContent:'space-evenly'}}>
							<Pressable style={{height: '100%', justifyContent: 'center'}} onPress={() => {
								if (MerchPOS > 0) { setMerchPOS(MerchPOS-1); }
							}}>
								<Text style={{fontSize: 100, color:'#0D1317'}} selectable={false}>
									{'<'}
								</Text>
							</Pressable>
							<Pressable style={{height: '95%', width: '65%', alignSelf: 'center'}} onPress={() => Linking.openURL('https://www.bonfire.com/store/spf-50-merch/')}>
								{Merch[MerchPOS]}
							</Pressable>
							<Pressable style={{height: '100%', justifyContent: 'center'}} onPress={() => {
								if(MerchPOS < (Merch.length-1)) { setMerchPOS(MerchPOS+1); }
							}}>
								<Text style={{fontSize: 100, color:'#0D1317'}} selectable={false}>
									{'>'}
								</Text>
							</Pressable>
						</View>
					</View>
					<View style={{backgroundColor: '#9cb6b3', flex: 1, marginRight: 25, height: '95%', borderRadius: 20, alignItems: 'center'}}>
						<Text style={{fontSize: 24, color: '#2C2C2C'}}>
							More Merch Available Over at Bonfire
						</Text>
						<Pressable style={{ flex: 1, flexDirection: 'row', flexWrap: 'wrap', width: '100%', justifyContent: 'center', alignItems: 'center'}} onPress={() => Linking.openURL('https://www.bonfire.com/store/spf-50-merch/')}>
							<Image style={{ width: '95%', height: '95%'}} source={Merch_Photos.Bonfire} resizeMode={'contain'}/>
						</Pressable>
					</View>
				</View>
			</View>
			<Footer/>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: 'white',
		alignItems: 'center',
		justifyContent: 'center',
	},
	navText: {
		padding: 20,
		fontSize: 16,
		borderTopWidth: 1,
		borderRightWidth: 1,
		borderColor: 'black',
	}
});
