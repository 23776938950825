import React, {useState} from 'react';
import { StyleSheet, Text, View, Image, ImageBackground, Linking, TextInput, Pressable} from 'react-native';
import * as ImagePicker from 'expo-image-picker';

import { Footer, Icons, NavBar } from '../../config/Config';

import Auth from '../../modules/Auth';

export default function App({navigation}) {
	const [TextThree, setTextThree] = useState('');
	const [TextSeven, setTextSeven] = useState('');
	const [caption, setCaption] = useState('');

	const [image, setImage] = useState(null);

	const pickImage = async () => {
		// No permissions request is necessary for launching the image library
		let result = await ImagePicker.launchImageLibraryAsync({
			mediaTypes: ImagePicker.MediaTypeOptions.Images,
			aspect: [4, 3],
			quality: 1,
		});
		
		setImage(result.uri);
	};

	return (
		<View style={{flex: 1, backgroundColor: '#0D1317'}}>
			<NavBar active={'Events'}/>
			<View style={{flexGrow: 1, marginTop: 15, justifyContent: 'center', alignItems: 'center'}}>
				<View style={{backgroundColor: '#9cb6b3',alignItems: 'center', borderRadius: 20, height: '80%', width: '55%', margin: 15}}>
					<Pressable style={{backgroundColor: '#202A31', width: '85%', height:'80%', justifyContent: 'center', margin: 10, borderRadius: 25}} onPress={pickImage}>
						{image ?  <Image source={{uri:image}} style={{width: '100%', height: '100%', resizeMode: 'contain'}}/> : <Text style={{width: 50, height: 50, alignSelf: 'center', fontSize: 30, borderRadius: 50, textAlign: 'center', borderColor: 'black', borderWidth: 2, margin: 10}} >
							+
						</Text>}
					</Pressable>
					<TextInput style={{width: '95%', height: 50, padding: 15, fontSize: 18, backgroundColor: '#202A31', color: 'white', textAlign: 'center', position: 'absolute', bottom: 0, marginBottom: 10, borderRadius: 15}} maxLength={75} placeholder='Come check us out while while we do our stuff! :)' placeholderTextColor={'grey'} onChangeText={setCaption} value={caption}/>
				</View>
				<View style={{flexDirection: 'row', alignItems: 'center'}}>
					<TextInput style={{width: 65, height: 50, padding: 10, fontSize: 18, backgroundColor: '#202A31', color: 'white', textAlign: 'center'}} maxLength={3} placeholder='123' placeholderTextColor={'gray'} onChangeText={setTextThree} value={TextThree}/>
					<Text style={{color: 'white', fontSize: 24, margin: 10}}>
					-
					</Text>
					<TextInput style={{width: 100, height: 50, padding: 15, fontSize: 18, backgroundColor: '#202A31', color: 'white', textAlign: 'center'}} maxLength={7} placeholder='1234567' placeholderTextColor={'grey'} onChangeText={setTextSeven} value={TextSeven}/>
					<Pressable style={{backgroundColor: '#202A31', marginLeft: 10}} onPress={() => {
						Auth({
							Id: `${TextThree}-${TextSeven}`,
							Description: caption,
							Img: image,
							nav: navigation
						});
					}}>
						<Text style={{fontSize: 18, color: 'lightgrey', padding: 15}}>
						Submit
						</Text>
					</Pressable>
				</View>
			</View>
			<Footer/>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: 'white',
		alignItems: 'center',
		justifyContent: 'center',
	},
	navText: {
		padding: 20,
		fontSize: 16,
		borderTopWidth: 1,
		borderRightWidth: 1,
		borderColor: 'black',
	}
});
