// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: 'AIzaSyC0390CbtjcGwpD3RUSv1LVh9_MouxP__Y',
	authDomain: 'spf50-85d41.firebaseapp.com',
	projectId: 'spf50-85d41',
	storageBucket: 'spf50-85d41.appspot.com',
	messagingSenderId: '623927945002',
	appId: '1:623927945002:web:99baafc8c1888bd1a02053',
	measurementId: 'G-HFCBMEV367'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);

export {app, db};