import React, {useEffect, useState} from 'react';
import { StyleSheet, Text, View, Image, ImageBackground, Pressable, Dimensions, ScrollView} from 'react-native';
import { Link } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { Icons } from '../../config/Config';

export default function HomeMobile() {

	let WindowWidth = Dimensions.get('screen').width;

	useEffect(() => {
		const script = document.createElement('script');
		script.src = '//www.instagram.com/embed.js';
		script.async = true;

		document.body.appendChild(script);

		return() => {
			document.body.removeChild('script');
		};
	});

	return (
		<ScrollView style={{flex: 1, backgroundColor: '#0D1317'}} scrollEnabled={true}>
			<View style={{ width: '100%', height: '100%', alignItems: 'center'}}>
				<View style={{ width: '95%', height: '60%', alignItems: 'center', backgroundColor: '#9cb6b3', borderRadius: 20, marginTop: 10}}>
					<Text style={{fontSize: 18	, color: '#2C2C2C', fontWeight: '400', fontStyle: 'italic', textAlign: 'center'}}>
								New Album
					</Text>
					<Text style={{fontSize: 18, color: '#2C2C2C', fontWeight: '400', marginBottom: 2, fontStyle: 'italic', textAlign: 'center'}}>
								Listen on all major streaming platforms
					</Text>
					<View style={{height: '85%', width: '95%'}}>
						<Image source={Icons.SPFRecentAlbumCover} style={{width: '100%', height: '100%', alignSelf: 'baseline', resizeMode: 'contain', borderRadius: 35}}/>
					</View>
				</View>
				<View style={{ width: '95%', height: '60%', alignItems: 'center', backgroundColor: '#9cb6b3', borderRadius: 20, marginTop: 5}}>
					<Text style={{fontSize: 18	, color: '#2C2C2C', fontWeight: '400', fontStyle: 'italic', textAlign: 'center'}}>
						Give a Listen or Follow
					</Text>
					<iframe src="https://open.spotify.com/embed/artist/4TrQNQ9vtodaCeu4a6QjPZ?utm_source=generator" width={'95%'} height={'95%'} frameBorder={0} style={{marginBottom: 5, borderRadius: 10}}/>
					<iframe src="https://www.instagram.com/spf50music/embed/" allowtransparency="true" scrolling="no" width={'95%'} height={'95%'} style={{marginBottom: 5, borderRadius: 10}} frameBorder={0}/>
				</View>
			</View>
		</ScrollView>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: 'white',
		alignItems: 'center',
		justifyContent: 'center',
	},
	navText: {
		padding: 5,
		fontSize: 12,
		borderTopWidth: 1,
		borderRightWidth: 1,
		borderColor: 'black'
	}
});
