import React, {useState} from 'react';
import { StyleSheet, Text, View, Image, ImageBackground, Pressable} from 'react-native';
import { Link } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { Icons, NavBar, Footer } from '../../config/Config';

export default function App() {
	const [Homeactive, setHomeActive] = useState(true);

	return (
		<View style={{flex: 1, backgroundColor: '#0D1317'}}>
			<NavBar active={'Listen'}/>
			<View style={{flexGrow: 1, flexDirection: 'row'}}>
				<View style={{flex: 1, marginTop: 15, flexDirection: 'row', justifyContent: 'space-evenly'}}>
					<View style={{flex: 1, alignItems: 'center', height: '95%', marginHorizontal: 20}}>
						<View style={{ width: '95%', height: '100%', alignItems: 'center', backgroundColor: '#9cb6b3', borderRadius: 20}}>
							<Text style={{fontSize: 24, color: '#2C2C2C'}}>
								Top Tracks:
							</Text>
							<iframe src="https://open.spotify.com/embed/artist/4TrQNQ9vtodaCeu4a6QjPZ?utm_source=generator" width={'95%'} height={'95%'} frameBorder={0} style={{marginBottom: 5, borderRadius: 10}}/>
						</View>
					</View>
					<View style={{backgroundColor: '#9cb6b3', flex: 1, marginRight: 25, height: '95%', borderRadius: 20, alignItems: 'center'}}>
						<Text style={{fontSize: 24, color: '#2C2C2C'}}>
							Available On All Major Streaming Platforms
						</Text>
						<View style={{flexDirection: 'row', flexWrap: 'wrap', width: '100%'}}>
							<Pressable style={{ width: '45%', height: 150, marginTop: 50, marginHorizontal: 10}} onPress={()  => Linking.openURL('https://open.spotify.com/artist/4TrQNQ9vtodaCeu4a6QjPZ')}>
								<Image style={{ width: '100%', height: '100%'}} source={Icons.spotify} resizeMode={'contain'}/>
							</Pressable>
							<Pressable style={{ width: '45%', height: 150, marginTop: 50, marginHorizontal: 10}} onPress={()  => Linking.openURL('https://music.apple.com/us/artist/spf-50/1616983484')}>
								<Image style={{ width: '100%', height: '100%'}} source={Icons.apple} resizeMode={'contain'}/>
							</Pressable>
							<Pressable style={{ width: '45%', height: 150, marginTop: 50, marginHorizontal: 10}} onPress={()  => Linking.openURL('https://soundcloud.com/spf-50')}>
								<Image style={{ width: '100%', height: '100%'}} source={Icons.soundcloud} resizeMode={'contain'}/>
							</Pressable>
							<Pressable style={{ width: '45%', height: 150, marginTop: 50, marginHorizontal: 10}} onPress={()  => Linking.openURL('https://www.youtube.com/channel/UCdCWSwPFajH50IIeJOMgiJQ')}>
								<Image style={{ width: '100%', height: '100%'}} source={Icons.yt} resizeMode={'contain'}/>
							</Pressable>
						</View>
					</View>
				</View>
			</View>
			<Footer/>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: 'white',
		alignItems: 'center',
		justifyContent: 'center',
	},
	navText: {
		padding: 20,
		fontSize: 16,
		borderTopWidth: 1,
		borderRightWidth: 1,
		borderColor: 'black',
	}
});
