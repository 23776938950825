import { getStorage, ref, updateMetadata, uploadString } from 'firebase/storage';
import {getAuth, signInWithCustomToken} from 'firebase/auth';
import { FieldPath, doc, serverTimestamp, setDoc} from 'firebase/firestore';
import { db } from '../config/firebase';

export default function Auth({Id, Description, Img, nav}) {
	const today = new Date();
	const storage = getStorage();

	const authRequest = new XMLHttpRequest();

	authRequest.open('PUT', 'https://us-east1-spf50-85d41.cloudfunctions.net/SPF50EventUpload');
	authRequest.send(Id);

	const fileName = (Img !== null) ? today.toDateString() + ' ' + (Img.substring(Img.lastIndexOf('/')-8)).replace(/\//g, '') : undefined;
	const ImageEvent = (Img) ? ref(storage, `Events/${fileName}`) : undefined;

	authRequest.onload = () => {
		const auth = getAuth();
		if (authRequest.status === 200) {
			signInWithCustomToken(auth, authRequest.response).then(() => {
				uploadString(ImageEvent, Img, 'data_url').then((snapshot) => {
					nav.navigate('SPFHome');
				}).catch((e) => {
					console.log('error uploading', e);
				});

				setDoc(doc(db, 'Events', fileName), {
					ImageLink: `Events/${fileName}`,
					Caption: Description,
					TS: serverTimestamp()
				});

			}).catch((e) => {
				console.log('error logging in', e);
			});
		}
	};
}