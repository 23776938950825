import React from 'react';
import { StyleSheet, Text, View, Image, ImageBackground, Linking, Pressable} from 'react-native';
import { Link } from '@react-navigation/native';

import { Icons } from '../config/Config';

export default function NavBar({active}) {
	return(
		<View style={{ width: '100%', backgroundColor: '#9cb6b3', flexDirection: 'row'}}>
			<Link style={{width: 200, height: '100%'}} to={{screen: 'Home'}}>
				<Image source={Icons.SPFLogo} style={{width: '100%', height: '100%', resizeMode: ''}}/>
			</Link>
			<View style={{ height: '100%', flexGrow: 1, flexDirection: 'row', alignItems: 'flex-end', marginHorizontal: 5}}>
				<Link style={[styles.navText,{borderLeftWidth: 1, backgroundColor: (active == 'Home') ? '#749D98' : undefined }]} to={{screen: 'SPFHome'}}>
		Home
				</Link>
				<Link style={[styles.navText, { backgroundColor: (active == 'Listen') ? '#749D98' : undefined}]} to={{screen: 'Listen'}}>
		Listen
				</Link>
				<Link style={[styles.navText, { backgroundColor: (active == 'Events') ? '#749D98' : undefined}]} to={{screen: 'Events'}}>
		Events
				</Link>
				<Link style={[styles.navText, { backgroundColor: (active == 'Merch') ? '#749D98' : undefined}]} to={{screen: 'Merch'}}>
		Merch
				</Link>
				{/* <Link style={[styles.navText, { backgroundColor: (active == 'About Us') ? '#749D98' : undefined}]} to={{screen: 'About'}}>
		About Us
				</Link>
				<Link style={[styles.navText, { backgroundColor: (active == 'Contact Us') ? '#749D98' : undefined}]} to={{screen: 'Contact'}}>
		Contact Us
				</Link> */}
			</View>
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: 'white',
		alignItems: 'center',
		justifyContent: 'center',
	},
	navText: {
		padding: 20,
		fontSize: 16,
		borderTopWidth: 1,
		borderRightWidth: 1,
		borderColor: 'black',
	}
});