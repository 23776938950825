import 'react-native-gesture-handler';
import { PlatformPressable } from '@react-navigation/elements';
import React, {useState} from 'react';
import { StyleSheet, Text, View, Image, ImageBackground, Linking, Dimensions, Platform, Pressable} from 'react-native';
import { DrawerActions, Link, NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createDrawerNavigator } from '@react-navigation/drawer';

import { } from './config/firebase';

import { Icons } from './config/Config';

// pages Desktop
import SPFHome from './pages/SPF50/SPFHome';
import Listen from './pages/SPF50/Listen';
import Events from './pages/SPF50/Events';
import Merch from './pages/SPF50/Merch';
import About from './pages/SPF50/About Us';
import Contact from './pages/SPF50/Contact Us';
import Login from './pages/SPF50/Login';

// pages Mobile
import SPFHomeMobile from './pages/Mobile/SPFHomeMobile';
import ListenMobile from './pages/Mobile/Listen';
import EventsMobile from './pages/Mobile/Events';
import MerchMobile from './pages/Mobile/Merch';
import AboutMobile from './pages/Mobile/About Us';
import ContactMobile from './pages/Mobile/Contact Us';
import LoginMobile from './pages/Mobile/Login';

const Stack = createNativeStackNavigator();
const Drawer = createDrawerNavigator();

export default function App() {

	const WindowWidth = Dimensions.get('screen').width;
	const WindowHeight = Dimensions.get('screen').height;
	let DeviceType = 'desktop';

	if (WindowWidth < 450) DeviceType = 'mobile';

	console.log(WindowWidth);
	console.log(WindowHeight);

	return (
		<NavigationContainer linking={{enabled: true}}>
			{ DeviceType == 'mobile' ?
				<Drawer.Navigator screenOptions={{
					drawerLabelStyle: { fontWeight: '700', borderBottomWidth: .5, borderColor: '#2C2C2C', color: '#2C2C2C' },
					drawerStyle: { backgroundColor: '#9cb6b3', width: 150 },
					header: ({ navigation }) => {
						// fix the drawer container to allow for tapping in dead space to minimize drawer
						return (
							<View style={{ width: '100%', height: 50, backgroundColor: '#9cb6b3', flexDirection: 'row' }}>
								<PlatformPressable
									accessible
									accessibilityRole="button"
									android_ripple={{ borderless: true }}
									onPress={() => navigation.toggleDrawer()}
									style={{ zIndex: 1, position: 'absolute', alignItems: 'flex-start', margin: 10 }}
									hitSlop={Platform.select({
										ios: undefined,
										default: { top: 16, right: 16, bottom: 16, left: 16 },
									})}
								>
									<Image
										style={{ height: 24, width: 24, margin: 3, resizeMode: 'contain' }}
										source={require('./assets/SPF50 Images/toggle-drawer-icon.png')}
										fadeDuration={0} />
								</PlatformPressable>
								<Pressable style={{width: '100%', height: '100%'}} onPress={() => navigation.navigate('LoginMobile')}>
									<Image source={Icons.footerBanner} style={{ width: '100%', height: '100%', resizeMode: 'contain' }}/>
								</Pressable>
								<Link style={{ width: 90, height: '70%', marginRight: 5, zIndex: 1, position: 'absolute', right: 0, alignSelf: 'center'}} to={{screen: 'Home'}}>
									<Image source={Icons.SPFLogo} style={{ width: '100%', height: '100%', resizeMode: 'contain'}} />
								</Link>
							</View>
						);
					}
				}}>
					<Drawer.Group>
						<Drawer.Screen name='SPFHome' component={SPFHomeMobile} />
						<Drawer.Screen name='Listen' component={ListenMobile} />
						<Drawer.Screen name='Events' component={EventsMobile} />
						<Drawer.Screen name='Merch' component={MerchMobile} />
						{/* <Drawer.Screen name='About' component={AboutMobile} /> */}
						{/* <Drawer.Screen name='Contact' component={ContactMobile} /> */}
						<Drawer.Screen name='LoginMobile' component={LoginMobile} options={{
							drawerItemStyle: {height: 0}
						}}/>
					</Drawer.Group>
				</Drawer.Navigator>
				:
				<Stack.Navigator screenOptions={{headerShown: false}}>
					<Stack.Screen name='SPFHome' component={SPFHome}/>
					<Stack.Screen name='Listen' component={Listen}/>
					<Stack.Screen name='Events' component={Events}/>
					<Stack.Screen name='Merch' component={Merch}/>
					{/* <Stack.Screen name='About' component={About}/> */}
					{/* <Stack.Screen name='Contact' component={Contact}/> */}
					<Stack.Screen name='Login' component={Login}/>
				</Stack.Navigator>
			}
		</NavigationContainer>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: 'white',
		alignItems: 'center',
		justifyContent: 'center',
	},
	navText: {
		padding: 20,
		fontSize: 16,
		borderTopWidth: 1,
		borderRightWidth: 1,
		borderColor: 'black'
	}
});
