import React, {useEffect, useState} from 'react';
import { StyleSheet, Text, View, Image, ImageBackground, Linking, Dimensions} from 'react-native';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';
import { collection, getDocs } from 'firebase/firestore';

import { Footer, Icons, NavBar } from '../../config/Config';
import { db } from '../../config/firebase';
import { FlatList } from 'react-native-gesture-handler';

function RenderPosts({caption, url}) {
	return (
		<View style={{alignItems: 'center', marginVertical: 10, width: '85%', height: Dimensions.get('window').width * .80, backgroundColor: '#9cb6b3', alignSelf: 'center', borderRadius: 20}}>
			<View style={{width: '90%', height: '75%', marginTop: 5}}>
				<Image source={{uri: url}} style={{width: '100%', height: '100%', aspectRatio: 1, resizeMode: 'contain', borderRadius: 20}}/>
			</View>
			<Text>
				{caption}
			</Text>
		</View>
	);
}

export default function App() {
	const [Posts, setPosts] = useState([]);

	useEffect(() => {
		const fetchPosts = async() => {
			const snapshot = await getDocs(collection(db, 'Events'));
		
			const storage = getStorage();

			snapshot.forEach((doc) => {
				getDownloadURL(ref(storage, `${doc.data().ImageLink}`)).then((firebaseurl) => {
					console.log(doc.data().TS);
					setPosts(prevPosts => [...prevPosts, {Caption: doc.data().Caption, url: firebaseurl, timestamps: doc.data().TS}]);
				});
			});
			setPosts(Posts.sort((a, b) => a - b));
		};
		fetchPosts();
	}, []);


	return (
		<FlatList
			data={Posts}
			renderItem={({ item }) => (<RenderPosts caption={item.Caption} url={item.url} />)}
			style={{backgroundColor: '#0D1317'}}
			showsVerticalScrollIndicator={false}
		/>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: 'white',
		alignItems: 'center',
		justifyContent: 'center',
	},
	navText: {
		padding: 20,
		fontSize: 16,
		borderTopWidth: 1,
		borderRightWidth: 1,
		borderColor: 'black',
	}
});
